import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "../styles/templates/news-article.module.scss"
import pageStyles from "../styles/pages/page-styles.module.scss"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const NewsArticle = props => {
  let article = props.data.contentfulNewsArticle
  let summary = article.summary
    ? article.summary.summary
    : article.altSummary.childMarkdownRemark.excerpt

  let pdfLink
  if (article.pdf)
    pdfLink = `<a href=${article.pdf.file.url} class=${
      styles.pdfLink
    } target="_blank" rel="noopener noreferrer"><div>${
      article.pdfLinkText ? article.pdfLinkText : "Read Article"
    }</div></a>`

  let markup = {
    __html: pdfLink
      ? article.articleText.childMarkdownRemark.html + pdfLink
      : article.articleText.childMarkdownRemark.html,
  }

  let sidebarMarkup
  if (article.sidebarText) {
    sidebarMarkup = { __html: article.sidebarText.childMarkdownRemark.html }
  }

  return (
    <Layout location={props.location.pathname}>
      <SEO title={article.title} article description={summary} />
      <div
        className={pageStyles.pageContentContainer}
        style={{ maxWidth: "1160px" }}
      >
        <div className={styles.container}>
          <div className={cx("heading", { noSidebar: !article.sidebarText })}>
            <h1>{article.title}</h1>
            <p>{article.author}</p>
            <p>{article.hideDate ? "" : article.date}</p>
          </div>
          <div className={cx("content", { noSidebar: !article.sidebarText })}>
            <div className={styles.article} dangerouslySetInnerHTML={markup} />
            {article.sidebarText && (
              <div
                className={styles.sidebar}
                dangerouslySetInnerHTML={sidebarMarkup}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NewsArticle

export const query = graphql`
  query NewsArticleByTitle($title: String!) {
    contentfulNewsArticle(title: { eq: $title }) {
      author
      articleText {
        childMarkdownRemark {
          html
        }
      }
      sidebarText {
        childMarkdownRemark {
          html
        }
      }
      altSummary: articleText {
        childMarkdownRemark {
          excerpt(format: PLAIN, pruneLength: 180)
        }
      }
      summary {
        summary
      }
      date(formatString: "MM/DD/YYYY")
      hideDate
      title
      pdf {
        file {
          url
        }
      }
      pdfLinkText
    }
  }
`
